import Head from 'next/head';
import { useRouter } from 'next/router';

type TitleAndMetaTagsProps = {
  title?: string;
  description?: string;
  image: string;
  url?: string;
  pathname?: string;
}

export function TitleAndMetaTags({
  title = 'Scale UI Radix',
  description = 'Everything you need to build a Scale App.',
  image,
  url = 'http://ui.scale.com',
  pathname,
}: TitleAndMetaTagsProps) {
  const router = useRouter();

  const imageUrl = image.startsWith('http') ? image : `${url}/social/${image}`;
  const path = pathname || router.pathname;

  return (
    <Head>
      <title>{title}</title>

      <meta content={description} name="description" />

      <meta content={`${url}${path}`} property="og:url" />
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      <meta content={imageUrl} property="og:image" />

      <meta content="@radix_ui" name="twitter:site" />
      <meta content="summary_large_image" name="twitter:card" />
    </Head>
  );
}
